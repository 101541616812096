.container {
  display: flex;
  flex-direction: column;
  height: 140vh;
  font-family: 'Open Sans', sans-serif;
  background-color: #EEEEEE;
  align-items: center;
  padding: 20px;
}

.filter-and-info-panel {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
}

.filter-section, .content {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #FFFFFF;
  width: 100%;
  box-sizing: border-box;
  gap: 10px;
  z-index: 1000;
  position: relative;
}


input, button, .react-datepicker-wrapper input {
  font-family: 'Open Sans', sans-serif;
  padding: 8px 12px;
  border: 1px solid #508C9B;
  border-radius: 5px;
  background-color: #FFFFFF;
  transition: 0.3s;
}

input:focus, .react-datepicker-wrapper input:focus {
  border-color: #508C9B;
  box-shadow: 0 0 5px rgba(80, 140, 155, 0.3);
}

button {
  background-color: #134B70;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  padding: 8px 12px;
  margin-right: 4px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #201E43;
}

button:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(80, 140, 155, 0.5);
}

.react-datepicker-popper {
  z-index: 2000 !important;
}

.map-section{
  flex: 2.1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 500px;
}
.table-section {
  flex: 2.1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 500px;
  overflow: auto; /* Ensure the section itself is scrollable */
}

.table-geo {
  width: 100%;
  height: 500px;
  overflow: auto; /* Ensure the table is scrollable */
}
@media (max-width: 768px) {
  .map-section {
    height: 300px;
  }
}

@media (max-width: 480px) {
  .map-section {
    height: 200px;
  }
}

table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

table thead {
  background-color: #134B70;
  color: #ffffff;
}

table th, table td {
  padding: 5px 10px;
  border: 1px solid #CCCCCC;
  text-align: left;
}

table tbody tr:nth-child(even) {
  background-color: #f8f8f8;
}

table tbody tr:hover {
  background-color: #e0f4f5;
}

table td {
  color: #333333;
}

table th {
  font-weight: bold;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  table {
    font-size: 50%;
  }
}

.info-section {
  flex: 1;
  background-color: #FFFFFF;
  overflow-y: auto;
  width: 100%;
}

.info-section h2 {
  margin-top: 0;
  color: #201E43;
}

.info-section p {
  color: #134B70;
}

.selected-date {
  display: flex;
  align-items: center;
  padding-top: 0%;
  margin-bottom: 10px;
}

.calendar-icon {
  color: white;
}

.selected-date span {
  font-size: 100%;
  margin-left: 10px;
}

.characterization-table {
  font-size: 100%;
  overflow-y: auto;
}

@media (max-width: 768px) {
  .characterization-table {
    font-size: 70%;
  }
}

.toggle-container {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.toggle-switch {
  position: relative;
  width: 70px;
  height: 34px;
  background-color: #ccc;
  border-radius: 34px;
  cursor: pointer;
  transition: background-color 0.4s;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 30px;
  height: 30px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.4s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

input:checked + .toggle-slider {
  transform: translateX(36px);
}

.toggle-switch.on {
  background-color: #134B70;
}

.toggle-icon {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.map-icon {
  left: 10px;
  color: white;
}

.table-icon {
  right: 10px;
  color: white;
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loader {
  width: 60px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side,#000 90%,#0000);
  background:
          var(--_g) 0%   50%,
          var(--_g) 50%  50%,
          var(--_g) 100% 50%;
  background-size: calc(100%/3) 50%;
  animation: l3 1s infinite linear;
}

@keyframes l3 {
  20%{background-position:0%   0%, 50%  50%,100%  50%}
  40%{background-position:0% 100%, 50%   0%,100%  50%}
  60%{background-position:0%  50%, 50% 100%,100%   0%}
  80%{background-position:0%  50%, 50%  50%,100% 100%}
}

.valid-ip {
  color: green;
  font-weight: bold;
  font-size: 16px;
  margin-top: 5px;
}

.invalid-ip {
  color: red;
  font-weight: bold;
  margin-top: 5px;
}

.filter-section {
  position: relative;
}

.go-back-button {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 8px 16px;
  background-color: #124B70;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
}

.go-back-button:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
  font-weight: bold;
  font-size: 16px;
  padding-top: 4px;
}